header{
    width: 100%;
    padding: 20px 40px;
    @include flex($j-c:space-between, $a-i:center);
    @include fixed($z-i:99);
    @include transition($s:0.3s);
    @extend %border-box;
    
    @include mini-pad{
        padding: 10px 25px;
    }

    h1 a{
        width: 182px;
        height: 40px;
        color: transparent;
        @include bgi($size:cover){
            background-image: url('../img/h1_logo.svg');
        };
        z-index: 100;
        @extend %dis-bl, %relative;

        @include mini-pad{
            width: 120px;
            height: 25px;
        }
    }

    >nav{
        @include flex($j-c:flex-end, $a-i:center);

        >ul{
            @include flex;
            @include transition($s:0.3s);
            opacity: 0;
            
            a{
                color: $text-black;
            }
            
            >li{
                padding: 0 1.25em;
                @extend %relative;

                >a{
                    @extend %pointer;
                }

                >ul{
                    padding: 2.5em 1.25em 0;
                    @include absolute($top:1.3em, $left:0);
                    transform: scaleY(0);
                    transform-origin: top;
                    opacity: 0;
                    @include transition($s:0.3s);
                    
                    li{
                        >a{
                            margin-bottom: 20px;
                            white-space: nowrap;
                            @extend %dis-bl;
                        }
                    }
                }
                
                &:hover{
                    >ul{
                        transform: scaleY(1);
                        opacity: 1;
                    }
                }
            }

            @include pad{
                display: none;
            }
        }

        .search-btn{
            margin-right: 2.5em;
            margin-left: 1.25em;
            width: 32px;
            height: 32px;
            @include bgi($position:center center){
                background-image: url('../img/header_search.svg');
            };
            @extend %pointer;

            @include mini-pad{
                margin-right: 1.25em;
            }
        }

        .hamber-btn{
            width: 72px;
            min-height: 22px;
            z-index: 100;
            @extend %pointer, %relative;
            
            &::before,
            &::after{
                content: '';
                width: 100%;
                height: 1px;
                background-color: black;
                @include transition($s:0.3s);
                @extend %dis-bl;
            }

            &:before{
                margin-bottom: 20px;
                box-shadow: 0 10px 0 black;
            }
        }
        
        .hamber-btn.show{
            &:before{
                margin-top: 11px;
                margin-bottom: 0px;
                box-shadow: unset;
                transform: rotate(15.35deg);
            }
            
            &:after{
                transform: rotate(-15.35deg);
            }
        }
    }

    .header-fullscreen{
        width: 100%;
        @include absolute($top:0, $left:0, $z-i:99);
        display: none;
        
        .search-form-content,
        .header-fullscreen-content{
            height: 100vh;
            @include flex($j-c:center, $a-i:center);
            background-color: white;
            opacity: 0.9;
            backdrop-filter: blur(4px);
        }
    
        form{
            width: 100%;
            max-width: 600px;
            padding: 0 40px;
        }
    
        .header-fullscreen-wrapper{
            width: 100%;
            max-width: 1300px;
            padding: 0 40px;
    
            >ul:nth-of-type(1){
                padding-bottom: 12%;
                @include flex($j-c:flex-start);
                overflow-x: auto;
    
                &::-webkit-scrollbar {
                    height: 8px;
                    background: rgba(0, 0, 0, 0.1);
                }
                
                &::-webkit-scrollbar-thumb{
                    background-color: $k-milktea;
                }
    
                >li{
                    min-width: 20%;
    
                    >a{
                        margin-bottom: 30px;
                        color: $text-black;
                        @extend %dis-bl;
                    }
                    
                    >ul{
                        >li{
                            margin-bottom: 15px;
                        }
                    }
                }
    
                @include pad{
                    >li{
                        min-width: 25%;
                    }
                }
    
                @include mini-pad{
                    margin-bottom: unset;
                    padding-bottom: unset;
                    @include flex($d:column);
                    overflow-x: unset;
    
                    >li{
                        margin-bottom: 40px;
                        min-width: 25%;
                    }
                }
            }
    
            >ul:last-of-type{
                border-top: 1px solid $k-brown;
                padding-top: 10px;
                @include flex($w:wrap, $j-c:space-between);
                
                li{
                    margin-bottom: 10px;
                }
                
                @include pad{
                    li{
                        padding: 0 10px;
                    }
                }
    
                @include mini-pad{
                    @include flex($d:column);
                }
            }
            
            @include mini-pad{
                margin-top: 80px;
                padding: 0px 40px 40px;
                height: calc(100vh - 80px);
                overflow: auto;
                box-sizing: border-box;
            }
        }
    }
}

.header-scroll-down{
    background-color: white;
    opacity: 0.98;
    backdrop-filter: blur(4px);
}