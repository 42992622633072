.local-intro-content{
    .local-intro-title{
        padding-bottom: 200px;
        @include flex;
        @extend %relative;

        @include pad{
            padding-bottom: 300px;
        }

        .intro-title-text{
            margin-top: 240px;
            width: 33%;
            @include flex($j-c:center);

            p{
                margin-top: -4em;
                margin-right: 3%;
                writing-mode: vertical-lr;
                font-weight: 300;
                letter-spacing: 1em;
                color: $text-black;
                @extend %relative;
            }

            @include mini-pad{
                margin-top: 160px;
                width: 20%;
                @include flex($d:column, $a-i:center);

                p{
                    font-size: $tag;
                }
            }
        }

        .intro-title-content{
            width: 67%;
            @extend %border-box, %relative;
    
            @include mini-pad{
                width: 80%;
            }

            figure{
                margin-bottom: 50px;
                
                img{
                    margin-bottom: 25px;
                    width: 100%;
                }
                
                figcaption{
                    padding-right: 12.5%;
                    @extend %border-box;
                    color: $text-gray;
                }
            }
        }
    }

    .local-intro-section{
        margin-bottom: 150px;
        @include flex;
        @extend %relative;

        h2{
            width: 33%;
            @extend %border-box, %relative;

            @include mini-pad{
                width: 20%;
            }
        }

        .intro-section-content{
            padding-left: 12.5%;
            width: 67%;
            @extend %border-box, %relative;
    
            @include pad{
                padding-left: 20px;
            }

            @include mini-pad{
                width: 80%;
            }
        }
    }

    .local-intro-story{
        margin-bottom: 150px;
        @include flex;
        @extend %relative;

        h2{
            width: 33%;
            @extend %border-box, %relative;

            @include mini-pad{
                width: 20%;
            }
        }

        .intro-story-content{
            padding-right: 12.5%;
            width: 67%;
            @extend %border-box, %relative;
    
            @include pad{
                padding-right: 20px;
            }

            @include mini-pad{
                width: 80%;
            }

            .intro-story-wrapper{
                h3{
                    width: fit-content;
                    color: $form-red;
                    transform: translateX(calc(-50% + 102px));
                    transform-origin: center center;

                    &::after{
                        content: '';
                        width: 6px;
                        height: 4px;
                        margin-top: 10px;
                        border-radius: 15px;
                        background-color: $k-milktea;
                        @extend %dis-bl, %mar-auto;
                    }
                }

                ul{
                    margin-bottom: 60px;

                    li{
                        @include flex;
                        
                        p:nth-of-type(1){
                            border-right: 1px solid $k-milktea;
                            padding: 10px 0;
                            padding-right: 13px;
                            max-width: 103px;
                            width: 100%;
                            text-align: end;
                            color: $title-black;
                            @extend %relative, %border-box;

                            &::after{
                                content: '';
                                width: 16px;
                                height: 16px;
                                @include bgi($size:contain){
                                    background-image: url('../img/story_dec.svg');
                                };
                                @include absolute($top:15px, $right:-8px);
                                @extend %dis-bl;
                            }
                        }
                        
                        p:nth-of-type(2){
                            padding: 10px 0;
                            padding-left: 13px;
                            width: calc(100% - 103px);
                            font-family: $noto-sans;
                            color: $text-gray;
                        }
                    }
                }
            }
        }
    }

    .local-intro-section.crud:nth-of-type(odd){
        flex-direction: row-reverse;
    }

    .local-intro-section.crud:nth-of-type(even){
        .intro-section-content{
            padding-left: unset;
            padding-right: 12.5%;
            
            @include pad{
                padding-right: 20px;
            }
        }
    }

    .intro-title-link{
        margin-bottom: 50px;
        padding-right: 12.5%;
        @include flex($j-c:flex-end, $a-i:center);
        @extend %border-box;
        
        @include pad{
            @include flex($d:column);
        }

        >p{
            margin-right: 65px;

            &:hover{
                color: $text-black;

                span{
                    width: 24px;
                }
            }

            @include pad{
                margin-right: unset;
                margin-bottom: 25px;
            }
        }

        >a{
            max-width: 265px;

            img{
                width: 100%;
            }
        }
    }
}