.index-content{
    .index-video{  
        @extend %relative;
        
        video{
            margin-bottom: -3px;
            width: 100%;
            height: auto;
        }
    
        p{
            width: 100%;
            font-size: $h3*2;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            color: white;
            @include absolute($top:50%, $z-i:1);
            transform: translateY(-50%);
            @extend %text-a-c;

            @include mini-pad{
                font-size: $h3;
            }
        }
    }

    .index-about{
        margin-bottom: 220px;
        @include flex($j-c:space-between);

        h2{
            margin-top: 8%;
            width: 33%;

            @include mini-pad{
                width: 20%;
            }
        }
        
        .index-about-content{
            width: 67%;
            overflow: hidden;
            @include flex;
            
            @include mini-pad{
                width: 80%;
            }

            figure{
                min-width: 100%;
                @include transition($s: 0.5s);
                
                img{
                    margin-bottom: 25px;
                    width: 100%;
                }

                .img-desk{
                    @include mini-pad{
                        display: none;
                    }
                }

                .img-mobile{
                    @extend %dis-none;

                    @include mini-pad{
                        display: unset;
                    }
                }
                
                figcaption{
                    @extend %relative;

                    h3{
                        margin-bottom: 12px;
                        color: $title-black;
                    }
                    
                    p{
                        width: 87.5%;
                        color: $text-gray;
                    }
    
                    .pre-btn, .next-btn{
                        width: 85px;
                        height: 54px;
                        background: $arrow-bg;
                        @include flex($j-c:center, $a-i:center);
                        @extend %pointer;

                        &::after{
                            content: url('../img/arrow.svg');
                            @extend %dis-bl;
                        }
                    }
        
                    .pre-btn{
                        @include absolute($top:-82px, $left:0);
                    }
        
                    .next-btn{
                        @include absolute($top:-82px, $left:86px);

                        &::after{
                            transform: rotate(180deg);
                        }
                    }

                }
            }
            
            figure:first-of-type{
                .pre-btn{
                    background: rgba(29, 29, 29, 0.9);
    
                    &::after{
                        opacity: 0.2;
                    }
                }
            }

            figure:last-of-type{
                .next-btn{
                    background: rgba(29, 29, 29, 0.9);
    
                    &::after{
                        opacity: 0.2;
                    }
                }
            }
        }

        @include pad{
            margin-bottom: 100px;
        }
    }

    .index-area{
        width: 100%;
        height: 874px;
        overflow: hidden;
        @extend %relative;

        >img{
            width: 100%;
            height: 960px;
            @include absolute($top:0, $left:50%);
            transform: translateX(-50%);

            @include pad{
                width: 1920px;
                height: auto;
            }
        }

        >ul{
            height: 100%;
            @include flex;
            @extend %relative;

            li{
                width: 100%;
                height: 100%;
                flex-shrink: 1;
                @extend %border-box, %pointer, %relative;

                a{
                    height: 100%;
                    @extend %dis-bl;
                }
                
                h3{
                    padding: 45px 70px;
                    color: $text-black;
                    background: linear-gradient(180deg, #FFFFFF 0%, rgba(253, 253, 253, 0.895833) 32.29%, rgba(246, 246, 246, 0.503045) 67.71%, rgba(237, 237, 237, 0) 100%);
                    @include flex($j-c:space-between);
                    @include transition($s:0.5s);
                }

                &:hover{
                    h3{
                        color: white;
                        background: rgba(63, 54, 50, 0.9);
                    }

                    button{
                        opacity: 1;
                    }
                }

                button{
                    float: right;
                    border: 0;
                    width: 150px;
                    height: 54px;
                    background: $arrow-bg;
                    opacity: 0;
                    @include flex($j-c:space-between, $a-i:center);
                    @include absolute($right:0, $bottom:0);
                    @include transition($s:0.5s);
                    @extend %border-box, %pointer, %relative;
                    
                    &::before{
                        content: url('../img/arrow.svg');
                        transform: rotate(180deg);
                        @include absolute($left:1.6em);
                        @include transition($s:0.3s);
                        @extend %dis-bl;
                    }
                    
                    span{
                        @include absolute($right:1.6em);
                        @include transition($s:0.3s);
                        color: white;
                    }
                    
                    &:hover{
                        &::before{
                            @include absolute($left:7.4em);
                        }
                        
                        span{
                            @include absolute($right:3.6em);
                        }
                    }
            
                    @include mini-pad{
                        height: 45px;
                    }
                }
            }

            li+li{
                border-left: 2px solid white;
            }
        }

        @include pad{            
            ul{
                @include flex($d:column);

                li+li{
                    border-left: 0;
                }
            }
        }
    }

    .index-article{
        margin-top: 8%;
        margin-bottom: 180px;
        @include flex($j-c:space-between);

        @include pad{
            margin-bottom: 100px;
            padding: 0 20px;
            @include flex($d:column);
        }

        h2{
            width: 33%;
            @include bgi($position:left bottom){
                background-image: url('../img/index_article_bg.png');
            };
            
            @include pad{
                margin-bottom: 50px;
                width: 100%;
                background-image: unset;
                writing-mode: unset;
                letter-spacing: 0.05em;
                color: $text-black;
                display: unset;
    
                &::after{
                    margin-left: 20px;
                    content: url('../img/title_dec_line_row.svg');
                }
            }
        }
        
        .index-article-content{
            width: 67%;
            padding-right: 12.5%;
            @extend %border-box;

            .article-cards{
                @extend %dis-none;
            }

            >a{
                float: right;
                color: $text-gray;
                @include flex($f:inline-flex, $j-c:flex-end);
                @include transition($s:0.3s);

                &::after{
                    content: url('../img/arrow_black.svg');
                    width: 0;
                    margin-left: 0px;
                    transform: scaleX(0);
                    transform-origin: left;
                    @include transition($s:0.3s);
                    @extend %dis-in-bl;
                }
                
                &:hover{
                    &::after{
                        width: auto;
                        margin-left: 10px;
                        transform: scaleX(1);
                    }
                }
            }

            @include pad{
                width: 100%;
                padding-right: 0;
            }
        }
    }

    .index-links{
        min-height: 533px;
        @include flex;
        @include bgi($size:cover){
            background-image: url('../img/index_link_bg.png');
        };

        @include mini-pad{
            margin-bottom: 100px;
        }

        .index-links-content{
            width: 67%;

            @include mini-pad{
                width: 80%;
            }

            ul{
                @include basic_grid($grid-num1:3, $grid-num2:1fr, $grid-r-gap:0, $grid-c-gap:10px);
                max-width: 820px;
                margin-left: auto;
                padding-left: 20px;
                
                @include mini-pad{
                    // display: unset;
                }

                li{
                    a{
                        margin: 0 5px;
                        padding: 15px 20px;
                        width: 100%;
                        height: 373px;
                        overflow: hidden;
                        background-color: black;
                        @extend %border-box, %pointer, %relative, %dis-bl;
    
                        img{
                            height: 100%;
                            object-fit: cover;
                            object-position: center;
                            opacity: 0.8;
                            transform: scale(1);
                            transform-origin: center center;
                            @include transition($s:0.3s);
                            @include absolute($top:0, $left:0);
                        }
    
                        .img-desk{
                            @include mini-pad{
                                display: none;
                            }
                        }
    
                        .img-mobile{
                            @extend %dis-none;
    
                            @include mini-pad{
                                display: unset;
                            }
                        }
    
                        h4{
                            writing-mode: vertical-lr;
                            font-weight: 300;
                            color: white;
                            @include flex($a-i:center);
                            @extend %relative;
                            
                            span{
                                margin-top: 6px;
                                width: 1px;
                                height: 30px;
                                background-color: white;
                                @include flex($a-i:center);
                                @include transition($s:0.3s);
                                @extend %dis-bl, %relative;
                                
                                &::before{
                                    content: '';
                                    width: 5px;
                                    height: 4px;
                                    border-radius: 20px;
                                    background-color: white;
                                    @extend %dis-bl;
                                }
    
                                &::after{
                                    content: '';
                                    width: 15px;
                                    height: 15px;
                                    background-image: url('../img/index_link_dec.svg');
                                    transform: translateY(25px);
                                    @include transition($s:0.3s);
                                }
                            }
                        }
                        
                        &:hover{
                            .img-desk{
                                opacity: 0.5;
                                transform: scale(1.1);
                            }
                            
                            h4{
                                span{
                                    height: 50px;
    
                                    &::after{
                                        transform: translateY(45px);
                                    }
                                }
                            }
                        }
    
                        @include pad{
                            padding: 10px 5px;
                        }
    
                        @include mini-pad{
                            margin: 5px 0;
                            height: 200px;
                        }
                    }
                }                
            }
        }

        h2{
            width: 33%;
            
            @include mini-pad{
                width: 20%;
            }
        }
    }

    .index-contact{
        margin-bottom: 130px;
        @include flex;
        
        @include mini-pad{
            padding: 0 20px;
        }

        .index-contact-text{
            margin-top: 3%;
            width: 33%;
            @include flex($j-c:center);

            p{
                margin-left: 5%;
                writing-mode: vertical-lr;
                font-weight: 300;
                color: $text-gray;
                @extend %relative;

                &::before{
                    content: '※';
                    @include absolute($top:-1.2em);
                }
            }

            @include mini-pad{
                margin-right: 15px;
                width: 20%;
                @include flex($d:column, $a-i:center);

                p{
                    margin-top: 30px;
                    font-size: $tag;
                }
            }
        }

        .index-contact-content{
            width: 67%;
            margin-top: 3%;
            padding-right: 12.5%;
            @extend %border-box;

            @include mini-pad{
                width: 80%;
                padding-right: 0;
            }
        }
    }
}