.article-content{
    margin-top: 240px;
    margin-bottom: 180px;
    padding: 0 8%;
    @extend %border-box;

    @include pad{
        margin-top: 160px;
        margin-bottom: 100px;
        padding: 0 20px;
    }

    @include mini-pad{
        margin-top: 130px;
    }

    .article-wrapper{
        margin-bottom: 80px;
        @include flex;

        .title{
            width: 33%;
            padding-right: 60px;
            @extend %border-box;

            >p{
                margin-bottom: 20px;
                width: fit-content;
                color: $date-green;
                
                span{
                    margin-right: 12px;
                    padding: 2px 7px;
                    color: white;
                    background-color: $k-milktea;
                }
            }

            .title-content{
                h2{
                    margin-bottom: 50px;
                    font-weight: 500;
                }

                p{
                    color: $text-gray;
                }
            }
        }

        .info{
            width: 67%;
        }

        .info.video{
            width: 100%;
            height: 0;
            padding-bottom: 41.25%;
            @extend %relative;

            iframe{
                width: 100%;
                height: 100%;
                @include absolute($top:0, $left:0);
            }
        }

        >a{
            width: 50%;
            color: $title-black;
            @include flex($d:column);
            @extend %border-box;

            .pre-article,
            .next-article{
                margin-bottom: 5px;
                color: $form-red;
                @extend %dis-bl;
            }
        }

        >a:nth-of-type(1){
            border-right: 1px solid $k-cream;
            padding-right: 20px;
        }
        
        >a:nth-of-type(2){
            padding-left: 20px;
            align-items: flex-end;
        }
    }

    .article-wrapper:nth-of-type(1){
        @include pad{
            flex-direction: column;

            .title, .info{
                width: 100%;
                padding-right: 0;
            }

            .title{
                margin-bottom: 30px;
            }
        }
    }

    .article-wrapper:nth-of-type(2){
        border-top: 1px solid $k-cream;
        border-bottom: 1px solid $k-cream;
        padding: 35px 0;

        @include mobile{
            flex-direction: column;
            padding: 25px 0;

            >a{
                width: 100%;
                padding-left: 0;
                padding-right: 0;
            }

            >a:nth-of-type(1){
                border-right: 0;
                border-bottom: 1px solid $k-cream;
                padding-bottom: 20px;
            }
            
            >a:nth-of-type(2){
                padding-top: 20px;
            }
        }
    }

    .article-list{
        h2{
            margin-bottom: 30px;
            color: $text-gray;
        }

        .article-cards.video{
            @include basic_grid($grid-num1:3, $grid-num2:1fr, $grid-r-gap:35px, $grid-c-gap:30px);
            
            @include mini-pad{
                @include basic_grid($grid-num1:2, $grid-num2:1fr, $grid-r-gap:35px, $grid-c-gap:30px);
            }
        }
    }
}