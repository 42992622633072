// 斷點
$pad: 1024px;
$mini-pad: 600px;
$mobile: 420px;

//colors
$k-milktea: #BC9A7F;
$k-brown: #AD8351;
$k-cream: #EAE0D0;
$date-green: #7C9B64;
$title-black: #3F3632;
$text-black: #020311;
$text-gray: #676767;
$arrow-bg: rgba(63, 54, 50, 0.9);//pre-btn next-btn
$form-red: #C91E1D;
$form-ph: #828282;
$form-tip: #BDBDBD;

//font
$h2: 42px;
$m-h2: 30px;
$h3: 32px;
$m-h3: 25px;
$h4: 26px;
$m-h4: 21px;
$h5: 21px;
$m-h5: 17px;
$h6: 16px;
$m-h6: 15px;
$text: 16px;
$m-text: 15px;
$tag: 14px;
$m-tag: 13px;
$noto-sans: 'Noto Sans TC', sans-serif;//黑體
$noto-serif: 'Noto Serif TC', serif;//襯線體