//base
@import 'base/reset';
@import 'base/var';
@import 'base/extend';
@import 'mixins/mixins';

img{width: 100%;}

//layout
@import 'layout/footer';
@import 'layout/header';

//page
@import 'page/index';
@import 'page/about';
@import 'page/team';
@import 'page/article-list';
@import 'page/article';
@import 'page/contact-us';
@import 'page/brand-story';
@import 'page/relative-link';
@import 'page/local';

//font-family
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Noto+Serif+TC:wght@200;300;400;500;600;700;900&display=swap');

h2,
.h2{
    font-family: $noto-serif;
    font-size: $h2;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.05em;
    
    @include mini-pad{
        font-size: $m-h2;
        letter-spacing: 0.06em;
    }
}

h3,
.h3{
    font-family: $noto-sans;
    font-size: $h3;
    font-weight: 700;
    line-height: 160%;
    letter-spacing: 0.06em;
    
    @include mini-pad{
        font-size: $m-h3;
    }
}

h4,
.h4{
    font-family: $noto-sans;
    font-size: $h4;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.06em;
    
    @include mini-pad{
        font-size: $m-h4;
    }
}

h5,
.h5{
    font-family: $noto-sans;
    font-size: $h5;
    font-weight: 500;
    line-height: 160%;
    letter-spacing: 0.06em;
    
    @include mini-pad{
        font-size: $m-h5;
        line-height: 140%;
        letter-spacing: 0.05em;
    }
}

h6,
.h6{
    font-family: $noto-serif;
    font-size: $h6;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.07em;
    
    @include mini-pad{
        font-size: $m-h6;
        letter-spacing: 0.05em;
    }
}

p,
.text{
    font-family: $noto-sans;
    font-size: $text;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 0.04em;
    
    @include mini-pad{
        font-size: $m-text;
        line-height: 140%;
        letter-spacing: 0.03em;
    }
}

.tag{
    font-family: $noto-sans;
    font-size: $tag;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0.05em;
    
    @include mini-pad{
        font-size: $m-tag;
        line-height: 160%;
        letter-spacing: 0.06em;
    }
}

//hover箭頭樣式
.hv-arr{
    @include flex;
    color: $text-black;
    @include transition($s:0.5s);
    
    span{
        width: 24px;
        margin-left: 5px;
        border-bottom: 1px solid $k-brown;
        transform: translateY(-8px);
        @include transition($s:0.5s);
        @extend %dis-bl, %relative;

        &:after{
            content: '';
            width: 14px;
            height: 1px;
            background-color: $k-brown;
            @include absolute($right:0, $bottom:-1px);
            transform: rotate(20deg);
            transform-origin: right bottom;
            @extend %dis-bl;
        }
    }

    
    &:hover{
        color: $k-brown;

        span{
            width: 50px;
        }
    }
}

//表單
form{
    .form-content{
        margin-bottom: 25px;
        @include flex($w:wrap, $j-c:space-between);

        .form-wrapper{
            @extend %relative;

            input{
                width: 100%;
                border: 0;
                border-bottom: 1px solid $k-brown;
                padding: 5px;
                @extend %border-box;

                &:focus-visible{
                    outline: unset;

                    ~label{
                        top: -1em;
                        transform: scale(0.8);
                    }
                }
            }

            textarea{
                margin-top: 25px;
                width: 100%;
                border: 1px solid $k-brown;
                padding: 2em 5px 5px;
                @extend %border-box;

                ~label{
                    @include absolute($top:2em, $left:0.5em);
                }

                &:focus-visible{
                    outline: unset;

                    ~label{
                        top: 1.8em;
                        transform: scale(0.8);
                    }
                }
            }

            input.required{
                border-bottom: 1px solid $form-red;
            }

            textarea.required{
                border: 1px solid $form-red;
            }
            
            label{
                @include absolute($top:0, $left:0.5em);
                color: $form-ph;
                transform-origin: left top;
                @include transition($s:0.3s);
            }

            label.focus{
                top: -1em;
                transform: scale(0.8);
            }

            p{
                margin-top: 3px;
                color: $form-red;
            }
        }

    }

    button{
        float: right;
        border: 0;
        width: 150px;
        height: 54px;
        background: $arrow-bg;
        @include flex($j-c:space-between, $a-i:center);
        @extend %border-box, %pointer, %relative;
        
        &::before{
            content: url('../img/arrow.svg');
            transform: rotate(180deg);
            @include absolute($left:1.6em);
            @include transition($s:0.3s);
            @extend %dis-bl;
        }
        
        span{
            @include absolute($right:1.6em);
            @include transition($s:0.3s);
            color: white;
        }
        
        &:hover{
            &::before{
                @include absolute($left:7.4em);
            }
            
            span{
                @include absolute($right:3.6em);
            }
        }

        @include mini-pad{
            height: 45px;
        }
    }

    .form-content.w1{
        .form-wrapper{width: 100%;}
    }

    .form-content.w2{
        .form-wrapper{width: 46%;}
        
        @include mini-pad{
            .form-wrapper{width: 100%;}

            .form-wrapper:nth-of-type(1){
                margin-bottom: 25px;
            }
        }
    }
}

//主標題
.main-title{
    writing-mode: vertical-lr;
    letter-spacing: 0.8em;
    color: $text-black;
    @include flex($a-i:center);
    
    &::after{
        content: url('../img/title_dec_line.svg');
    }
}

//tab-control
.tab-list{
    width: 100%;
    margin-bottom: 35px;
    padding-bottom: 15px;
    overflow: auto;
    @include flex;

    &::-webkit-scrollbar {
        height: 8px;
        background: rgba(0, 0, 0, 0.1);
    }
    
    &::-webkit-scrollbar-thumb{
        background-color: $k-milktea;
    }

    li{
        min-width: 160px;
        @extend %pointer;

        a.active{
            color: $k-brown;
        }
    }
}

//articlelist-card
.article-cards{
    .article-card{
        margin-bottom: 30px;
        border-bottom: 1px solid $form-tip;
        padding-bottom: 40px;
        height: fit-content;
        @extend %relative;

        a>p{
            margin-bottom: 15px;
            width: fit-content;
            color: $date-green;
            
            span{
                margin-right: 12px;
                padding: 2px 7px;
                color: white;
                background-color: $k-milktea;
            }
        }

        .card-text-content{
            @include flex;
            padding-right: 8%;

            h3{
                margin-right: 40px;
                max-width: 40%;
                width: 100%;
                color: $title-black;
            }

            p{
                height: 4.5em;
                overflow: hidden;
                display: -webkit-box;
                text-overflow: ellipsis;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                white-space: normal;
                color: $text-gray;
            }

            @include mini-pad{
                display: unset;
                padding-right: unset;

                h3{
                    margin-bottom: 10px;
                }
            }
        }

        .gotopage-btn{
            width: 65px;
            height: 40px;
            background: $arrow-bg;
            @include absolute($right:0, $bottom:0);
            @include flex($j-c:center, $a-i:center);

            &::after{
                content: url('../img/arrow.svg');
                transform: rotate(180deg);
                @include transition($s:0.3s);
                @extend %dis-bl;
            }
        }
        
        &:hover{
            .gotopage-btn{
                &::after{
                    transform: translateX(10px) rotate(180deg);
                }
            }
        }
    }
}

//video-card
.video-card{
    .img{
        margin-bottom: 5px;
        width: 100%;
        overflow: hidden;
        @extend %relative;

        &::after{
            content: url('../img/video_play.png');
            @include absolute($top:50%, $left:50%);
            transform: translate(-50%, -50%);
        }

        img{
            width: 100%;
            @include transition($s:0.3s);
        }
    }

    h3{
        color: $title-black;
    }

    &:hover{
        .img img{
            transform: scale(1.1);
        }
    }
}