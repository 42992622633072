.brand-story-content{
    margin-top: 240px;
    margin-bottom: 100px;
    @include flex($j-c:space-between);

    @include pad{
        margin-top: 160px;
        margin-bottom: 20px;
        padding-left: 20px;
    }

    @include mini-pad{
        margin-top: 130px;
    }

    h2{
        width: 33%;
        
        @include mini-pad{
            width: 20%;
        }
    }

    .brand-story-wrapper{
        width: 67%;
        height: 500px;
        @extend %border-box, %relative;

        @include mini-pad{
            width: 80%;
        }

        .brand-story-bg{
            width: 100%;
            height: 100%;
            @include bgi($position:left center){
                background-image: url('../img/story_bg.png');
            };
            @include absolute($top:0, $left:0);
        }

        ul{
            padding-top: 10px;
            top: 104px;
            width: 100%;
            height: calc(100% + 40px);
            overflow: auto;
            @include flex;
            @extend %relative, %border-box;

            &::-webkit-scrollbar {
                @extend %dis-none;
            }

            &.active{
                cursor: grabbing;
            }

            li{
                margin: 0 55px;
                min-width: 160px;
                width: 160px;
                @extend %pointer;
               
                img{
                    width: 100%;
                    clip-path: polygon(0% 0%, 100% 0%, 100% 90%, 57% 90%, 50% 100%, 43% 90%, 0 90%);
                    @include transition($s:0.3s);
                }
                
                figcaption{
                    margin-top: 15px;
                    font-family: $noto-sans;
                    color: $k-milktea;
                    @extend %text-a-c;

                    span{
                        color: $text-black;
                        @include transition($s:0.3s);
                        @extend %dis-bl;
                    }
                    
                    p{
                        @include transition($s:0.3s);
                    }
                    
                    &::before{
                        content: '';
                        margin: auto;
                        margin-bottom: 15px;
                        width: 16px;
                        height: 16px;
                        @include bgi{
                            background-image: url('../img/story_dec.svg');
                        };
                        @extend %dis-bl;
                    }
                }

                &:hover{
                    img{
                        transform: translateY(-10px);
                    }
                    
                    figcaption span{
                        transform: translateY(10px);
                    }

                    figcaption p{
                        transform: translateY(10px);
                    }
                }
            }

            .last-story{
                img,
                figcaption p{
                    opacity: 0.3;
                }

                cursor: unset;
            }
        }

        >span{
            @include absolute($right:12%);
            
            @include pad{
                @include absolute($right:12%, $bottom:30px);
            }

            &:hover{
                color: $text-black;

                span{
                    width: 24px;
                }
            }
        }
    }
}

//popup
.brand-story-popup{
    width: 100%;
    height: 100vh;
    @include fixed($z-i:100);
    @include flex($j-c:center, $a-i:center);
    transform: scale(0);
    opacity: 0;
    @include transition($attr:opacity, $s:0.3s);
    transform-origin: center center;

    .pop-bg{
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.25);
        @include absolute($top:0, $left:0);
    }

    .popup-content{
        padding: 25px 50px 50px;
        width: 530px;
        max-height: 90%;
        background-color: white;
        overflow: auto;
        @extend %relative, %border-box;

        @include mini-pad{
            width: 360px;
        }

        @include mobile{
            padding: 25px;
            width: 260px;
        }

        .close-btn{
            float: right;
            margin-bottom: 25px;
            padding-top: 11px;
            width: 72px;
            min-height: 22px;
            @extend %pointer, %relative, %border-box;

            &::before,
            &::after{
                content: '';
                width: 100%;
                height: 1px;
                background-color: black;
                @extend %dis-bl;
            }

            &::before{
                box-shadow: unset;
                transform: rotate(15.35deg);
            }
            
            &::after{
                transform: rotate(-15.35deg);
            }
        }

        figure{
            img{
                margin-bottom: 20px;
                width: 100%;
            }

            figcaption{
                margin-bottom: 20px;
                color: $k-milktea;
                @extend %text-a-c;

                span{
                    margin-bottom: 5px;
                    color: $text-black;
                    @extend %dis-bl;
                }
            }
        }

        >p{
            color: $text-gray;
        }
    }
}

.brand-story-popup.show{
    transform: scale(1);
    opacity: 1;
}