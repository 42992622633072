.contact-content{
    margin-top: 240px;
    margin-bottom: 130px;
    @include flex;
    
    @include pad{
        margin-top: 160px;
    }
    
    @include mini-pad{
        margin-top: 130px;
        padding: 0 20px;
    }

    .index-contact-text{
        margin-top: 3%;
        width: 33%;
        @include flex($j-c:center);

        p{
            margin-left: 5%;
            writing-mode: vertical-lr;
            font-weight: 300;
            color: $text-gray;
            @extend %relative;

            &::before{
                content: '※';
                @include absolute($top:-1.2em);
            }
        }

        @include mini-pad{
            margin-right: 15px;
            width: 20%;
            @include flex($d:column, $a-i:center);

            p{
                margin-top: 30px;
                font-size: $tag;
            }
        }
    }

    .index-contact-content{
        width: 67%;
        margin-top: 3%;
        padding-right: 12.5%;
        @extend %border-box;

        @include mini-pad{
            width: 80%;
            padding-right: 0;
        }
    }
}