.about-content{
    margin-top: 80px;
    margin-bottom: 130px;
    
    @include pad{
        margin-top: 160px;
    }
    
    @include mini-pad{
        margin-top: 130px;
    }

    h3{
        margin-bottom: 25px;
        color: $k-brown;
    }
    
    .about-title{
        padding-bottom: 400px;
        @include flex;
        @extend %relative;

        @include pad{
            padding-bottom: 300px;
        }

        h2{
            padding-top: 160px;
            width: 33%;
            @extend %border-box, %relative;

            @include mini-pad{
                width: 20%;
            }
        }

        .about-title-content{
            width: 67%;
            @extend %border-box, %relative;
    
            @include mini-pad{
                width: 80%;
            }

            figure{
                img{
                    margin-bottom: 35px;
                    width: 100%;
                }

                h3{
                    color: $title-black;
                }
                
                p{
                    color: $text-gray;
                }
            }
        }

        .about-title-bg{
            width: 100%;
            min-height: 533px;
            @include bgi($position: bottom center, $size:cover){
                background-image: url('../img/index_link_bg.png');
            };
            @include absolute($bottom:0, $left:0);
        }
    }

    .about-local{
        margin-bottom: 160px;
        padding: 0 12.5% 0 25%;
        @include flex($a-i:center);
        @extend %border-box;

        .about-local-img{
            width: 65%;
            max-height: 533px;
            overflow: hidden;

            img{
                width: 100%;
            }
        }

        .about-local-text{
            width: 35%;
            padding-left: 50px;
            @extend %border-box;
        }

        @include pad{
            margin-bottom: 80px;
            padding: 0;
            flex-direction: column;
            
            .about-local-img,
            .about-local-text{
                width: 100%;
            }

            .about-local-img{
                margin-bottom: 20px;
            }
            
            .about-local-text{
                padding-left: 0;
                padding: 0 20px;
            }
        }
    }

    .about-sustainable{
        margin-bottom: 160px;
        padding: 0 12.5%;
        @include flex($a-i:center);
        @extend %border-box;

        .about-sustainable-text{
            padding-right: 50px;
            width: 40%;
            @extend %border-box;
        }

        .about-sustainable-img{
            width: 60%;
            @include flex($j-c:space-between);

            img{
                width: 100%;
            }
        }

        @include pad{
            margin-bottom: 80px;
            padding: 0 20px;
            flex-direction: column-reverse;

            .about-sustainable-text,
            .about-sustainable-img{
                width: 100%;
            }

            .about-sustainable-img{
                margin-bottom: 25px;
            }
        }
    }

    .about-create{
        padding: 0 12.5%;
        @include flex($a-i:center);

        .about-create-img,
        .about-create-text{
            width: 50%;
        }

        .about-create-img{
            img{
                width: 100%;
            }
        }

        .about-create-text{
            padding-left: 50px;
            @extend %border-box;
        }

        @include pad{
            padding: 0;
            flex-direction: column;

            .about-create-img,
            .about-create-text{
                width: 100%;
            }
            
            .about-create-img{
                margin-bottom: 25px;
            }

            .about-create-text{
                padding-left: 0;
                padding: 0 20px;
            }
        }
    }
}